import React from "react";

import Layout from "/src/components/Layout";

// Utilities
import kebabCase from "lodash/kebabCase";

// Components
import { Link, graphql } from "gatsby";

import './tags.scss'

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {

  return (
    <Layout>
      {/* <Helmet title={title} /> */}
      <div className="TagsPage">
        <h1 className="TagsPage__head">All Blogs by Tags
          <span className="apostspan" role="img" aria-label="target watch post">
            🎯
          </span>
        </h1>
        <ul className="TagsPage__ul">
          {group.map((tag) => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default TagsPage;
